body {
  background-color: #f2f4f4;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*html:not([data-scroll='0']) {*/
/*  padding-top: 64px;*/
/*}*/

html:not([data-scroll='0']) header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.main-container {
  height: 100vh;
  overflow-y: scroll;
}
.form-total-comment {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  padding: 7px 10px 7px 10px;
  margin-left: 15px;
  cursor: text;
  &.form-comment-disabled {
    background-color: #F5F5F5;
  }
  &.blog-comment {
    flex-direction: column;
    gap: 10px;
  }
}
.wrapper-editor {
  width: 100%;
  display: flex;
  [class~=DraftEditor-root] {
    width: calc(100% - 20px) !important;
  }
  [class~=DraftEditor-editorContainer] {
    width: 100% !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.loading-comment {
 margin-top: 3px !important;
}
.modal-preview {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}
.modal-preview-content .ant-modal-content {
  box-shadow: none;
  height: 100%;
  overflow-y: auto;
}
.modal-preview-content .ant-modal-header {
  border-bottom: 0;
}
.modal-preview-content .ant-modal-body {
  font-size: 16px;
}
.input-comment-post {
  border-radius: 6px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}
._4w79 {
  cursor: text;
  flex: 1 1 auto;
  line-height: 16px;
  overflow: hidden;
  color: #8d949e;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-interactive {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
}
.ant-input:focus {
  box-shadow: none !important;
}
/* customer date picker ant design */
.ant-layout-content {
  padding-left: 32px;
  padding-right: 32px;
}
.ant-layout-content > div {
  transition: height 2s;
}
.typing-ellipsis {
  position: absolute;
  bottom: -18px;
  left: 0;
}
.comment-count {
  position: absolute;
  bottom: -18px;
  right: 0;
  font-size: 11px;
}

.comment-count-edit {
  position: absolute;
  bottom: -45%;
  right: 0%;
  font-size: 11px;
}

/* customer tool-tip ant design  START */
.ant-tooltip .ant-tooltip-inner {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 0.875rem;
}
.ant-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}
.ant-tooltip-arrow span.ant-tooltip-arrow-content {
  background-color: #fff;
}

.tooltip-organizer .ant-tooltip .ant-tooltip-inner {
  color: #fff;
  background-color: #060606;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 0.875rem;
}
.tooltip-organizer .ant-tooltip-arrow span.ant-tooltip-arrow-content {
  background-color: #060606;
}
/* customer tool-tip ant design  END */

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.popup-date-picker {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
}
.ant-picker-header {
  border-bottom: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:hover {
  border: 0;
  background: rgba(0, 0, 0, 0.8);
}
.ant-picker-cell-today:hover {
  color: white;
  background: rgba(0, 0, 0, 0.8);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #060606;
}
.ant-picker-cell .ant-picker-cell-inner {
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.ant-picker-cell-today .ant-picker-cell-inner {
  background: rgba(6, 6, 6, 0.05);
  border-radius: 50%;
}
.ant-picker-content th {
  color: #8b8b8b;
}
/* .ant-picker-header-super-prev-btn {
  display: none;
}
.ant-picker-header-super-next-btn {
  display: none;
} */
.ant-picker-month-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-year-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-header {
  padding-top: 10px;
}

/* .ant-picker.heatmap-picker {
  cursor: pointer;
  border-radius: 4px;
  background-color: #f5f5f5 !important;
  width: 100%;
}

.heatmap-picker input {
  cursor: pointer !important;
} */

.ant-picker.heatmap-picker-area {
  border-radius: 0px 4px 4px 0px; 
  border: 1px solid #EFEFEF !important;
  background: #FDFDFD !important;
  padding: 7px 12px;
  width: calc(100% - 34px);
}

.ant-picker.heatmap-picker-area.ant-picker-disabled {
  background: #EFEFEF !important;
}

.heatmap-picker-area .ant-picker-input input {
  cursor: pointer;
  color: #1A1A1A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ant-picker.heatmap-picker-area.ant-picker-disabled input {
  cursor: not-allowed;
}

.heatmap-picker-area span {
  width: 18px;
  height: 18px;
  margin-left: 0;
}

.input-add-hours {
  width: 95%;
  height: 100%;
  border-radius: 4px;
  border: none;
  background: #FDFDFD;
  [class~="ant-input-number-handler-wrap"] {
    margin-right: 32px;
  }
  [class~="ant-input-number-handler-up"], [class~="ant-input-number-handler-down"] {
    display: none;
  }
  [class~="ant-input-number-handler-wrap"] {
    display: none;
  }
  [class~="ant-input-number-input-wrap"] {
    height: 100%;
    input {
      height: 100% !important;
      padding: 8px 12px;
      padding-right: 0;
      color: #1A1A1A;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
  [class~="ant-input-number"]:hover {
      border-color: transparent;    
  }
  [class~="ant-input-number-focused"] {
      border: 1px solid transparent !important;
      border-width: 1px;    
  }
  &[class~="ant-input-number"] {
    border-color: transparent !important;
  }
  &[class~="ant-input-number-focused"]{
    box-shadow: none !important;
  }
  [class~="ant-form-item-has-error"]{
    box-shadow: none !important;
  }
  [class~="ant-input-number"] {
    border-color: transparent !important;
  }
}
.text-area-heatmap {
  resize: none;
  color: #1A1A1A;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130% !important; 
  padding: 8px 12px;
  border: 1px solid transparent !important;
  &::-webkit-input-placeholder { 
    font-size: 14px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-picker-input input {
  font-size: 1rem;
  font-weight: 500;
  color: #060606;
}

/* end customer date picker ant design */
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.pt-8 {
  padding-top: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.outside-container {
  background-color: #fafafa;
}
.layout-outside {
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .layout-outside {
    padding-left: 66px;
    padding-right: 66px;
  }
}
/* custom drop down menu */

/* .ant-dropdown-placement-bottomRight { */
.ant-dropdown-menu-light {
  padding: 0px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08) !important;
  border-radius: 3px !important;
  color: #333333 !important;
  font-size: 16px !important;
}
.ant-popover-inner-content {
  padding: 4px 8px 4px 8px !important;
  border-radius: 6px !important;
}
/* } */
.ant-dropdown-menu {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-dropdown-menu-item {
  padding-left: 14px;
  padding-right: 14px;
}
.ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 18px;
}
/* end custom drop down menu */
.icon-trash {
  padding: 6px 10px 10px;
  background-color: #f5f5f5;
  border-radius: 3px;
}
.icon-clock {
  margin-top: 13px;
}

.history-copy .ant-dropdown-menu {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-item {
  min-height: auto;
  padding: 0;
}

.ant-comment-avatar img {
  border-radius: 6px;
}

.ant-comment-content {
  background: #f5f5f5;
  border-radius: 6px;
  padding: 9px 14px 7px 16px;
}

.ant-comment {
  width: 100%;
}
/* customer search input ant */
.ant-input-search-icon::before {
  border-left: none;
}
/* end customer search input ant */
::placeholder {
  font-size: 16px;
}

.ant-result-extra {
  display: flex;
  justify-content: center;
}
/* custom ant input */
.ant-input {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
.ant-input-affix-wrapper {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
/* end custom ant input */

/* start custom  ant-breadcrumb */
.ant-breadcrumb span.last-breadcrumb-items {
  font-size: 20px;
  color: #060606;
}

.ant-breadcrumb a:hover {
  color: #060606;
}

.ant-breadcrumb span.ant-breadcrumb-separator:last-child {
  font-size: 20px;
  font-weight: 200;
}
/* end custom  ant-breadcrumb */

.notification-container {
  background-color: #ffffff;
  width: 400px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.notification-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
}

.notification-loading {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/* width */
/* ::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
}
::-webkit-scrollbar-thumb:hover {
  background: #333333;
  width: 10px;
}

.DraftEditor-root {
  width: 100%;
  position: relative;
}

.updload-img {
  background-color: #fff;
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 8px;
  justify-content: center;
  height: 324px;
  width: 100%;
  display: flex;
}
.dropped-image {
  margin: auto;
}
.dropped-image img {
  max-width: 100%;
  max-height: 306px;
}
.dropped-image:hover {
  background-color: #7e7e7e;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content-html {
  word-break: break-word;
}
.content-html img,
.content-html iframe {
  max-width: 100%;
  height: auto;
}

.tokhoquama {
  color: #0b7bff;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol {
  border: none;
  width: 32px;
  height: 32px;
  margin-right: 9px;
  background: #f5f5f5;
  color: #333333;
  font-size: 15px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol:hover {
  background: #eaeaea;
}
.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 32px;
  height: 32px;
  border: none;
  margin-right: 9px;
  background: #eaeaea;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
}
/* class Name for component GiphyCard */
.giphy-search-box {
  width: 330px !important;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.giphy-search-box-from input {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-bottom: 5px;
}
.PoweredByGiphy_poweredByGiphy__1MuEY {
  display: none;
}
.Spinner_spinnerWrapper__Xu_O3 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*end class Name for component GiphyCard */
/* class Name for component Emoji */
.container-popup-emoji {
  min-width: 340px;
  min-height: 353px;
}
/* end class Name for component Emoji */
.loading-avatar {
  position: absolute;
  top: 50%;
  left: -2%;
  transform: translate(100%, -50%);
}
.loading-cover {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.nodata-pie-chart {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.feeling-dropdown {
  /* top: 43px !important; */
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 0;
}

.ant-group-title > .ant-form-item-label > label {
  color: #8b8b8b;
}
.list-item-comment {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
  margin-top: -2px;
  padding: 0px;
  align-items: flex-start;
  >li {
    height: 24px;
    width: 24px;
  }
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol {
  background: transparent;
  margin: 0px;
  width: 24px;
  height: 24px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 24px;
  height: 24px;
  margin-right: 0;
  cursor: pointer;
  font-size: 15px;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 5px;
  padding: 0 0.3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
  right: 50px;
}
.draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 12px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 12px solid white;
  border-left: 8px solid transparent;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  left: auto;
  right: 10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  transform: scale(1) translateY(-100%);
  top: -10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  top: 100%;
  border-top: 12px solid white;
  border-bottom: 0;
}

.cursor-pointer,
p.cursor-pointer {
  cursor: pointer;
}
#interactive-activity .item-user-view-cmt .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  transform: scale(0.8) translateY(-100%);
  right: -30px;
  top: -50px;
}

.item-user-view-cmt {
  [id^="input-comment-"]
  {
    position: relative;
     > div {
    bottom: 110% !important;
  }
  }
} 

#content-activity {
  position: relative;
}

.contain-btn-container {
  position: absolute;
  top: 35px;
  right: 39px;
}

.contain-edit-btn {
  background: rgba(245, 245, 245, 0.3);
  border-radius: 4px;
  display: inline-block;
}
.contain-edit-btn:hover {
  background: #f5f5f5;
}

.contain-read-btn:hover,
.contain-read-btn:focus {
  color: #060606;
  border-color: #060606;
}

.btn-img-edit {
  background-color: whitesmoke !important;
  border-radius: 20px !important;
  margin-right: 9px;
}

.container-comment-interactive {
  height: auto;
  margin-top: 18px;
  padding-top: 8px;
  position: absolute;
  bottom: 0;
  background: white;
  /* width: calc( 100% - 44px ); */
}
.contain-input-comment {
  width: 100%;
}
.form-total-comment {
  max-width: calc(100% - 47px);
}
.contain-input-comment {
  max-width: calc(100% - 72px);
}
#select-organizer .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  border: 0;
  background: transparent;
  margin-right: 16px;
}
#select-organizer .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
.list-mention-organizer {
  border-radius: 8px;
}
.list-mention-organizer .ant-select-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.organizer-select-option {
  width: 100%;
}
.select-mention-organizer {
  position: relative;
}
.select-mention-organizer .ant-select-selection-item {
  align-items: center;
  height: 48px !important;
  border-radius: 6px;
}
.select-mention-organizer .ant-select-selector {
  height: auto !important;
}
/* css modal redeem reward */
.modal-wrap-confirm-reward {
  background-color: rgba(51, 51, 51, 0.5);
}
.modal-confirm-reward {
  width: 435px;
  height: 355px;
  padding: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}
.modal-confirm-reward .ant-modal-content {
  height: 100%;
  padding-top: 16px;
}
.modal-confirm-reward .ant-modal-close-icon {
  transform: scale(1.3);
}

.modal-wrap-add-template, .modal-wrap-favorite-projects, .modal-wrap-edit-heatmap {
  background-color: rgba(26, 26, 26, 0.72);
}
.modal-wrap-add-template, .modal-wrap-favorite-projects {
  z-index: 1001;
}
.modal-add-template, .modal-edit-heatmap {
  width: 536px !important;
  padding-bottom: 0;
  [class~="ant-modal-content"] {
    border-radius: 6px;
    
    [class~="ant-modal-close"] {
      display: none;
    }
  }
  [class~="ant-modal-body"] {
    padding: 0;
  }
}
/* end css modal redeem reward */
.title-reward {
  text-align: center;
  line-height: 1;
}
.text-center {
  text-align: center !important;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.imt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.horizontal-menu {
  width: 100%;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.ant-tabs {
  overflow: unset;
}

.items-wrap {
  width: 100%;
  height: auto;
  position: relative;
}

.item-img {
  width: 100%;
  height: 384px;
  cursor: pointer;
  position: relative;
}

.items-wrap.wrap-1 .item-img {
  display: flex;
}

.items-wrap.wrap-1 .item-img > img {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.wrap-2 .item-img,
.wrap-3 .item-img,
.wrap-4 .item-img {
  width: 50%;
}

.wrap-5 .item-img {
  width: 25%;
}

.wrap-5 .item-img:first-child {
  width: 50%;
}

.wrap-3 .item-img:nth-child(2) {
  height: 192px;
}
.wrap-3 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(2) {
  height: 192px;
}

.wrap-4 .item-img:nth-child(3) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(4) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(2) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 0;
}

.wrap-5 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 0;
}

.wrap-5 .item-img:nth-child(4) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(5) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.small-img {
  width: 50%;
  height: 192px;
}

.half-img {
  width: 100%;
  height: 192px;
}

.images-2 {
  width: 100%;
  height: 192px;
}

.images-3 {
  height: 192px;
  width: 100%;
}

.images-4 {
  width: 100%;
  height: 192px;
}

.image-gallery-fullscreen-button {
  top: 0;
  right: 16px;
  height: 20px;
  display: flex;
  position: absolute;
}

.image-gallery-fullscreen-button .image-gallery-svg.image-gallery-svg {
  width: 28px;
  height: 28px;
}

.active {
  top: 15px !important;
}

.modal-preview-images .ant-modal-content {
  box-shadow: unset;
  background-color: unset;
}

.modal-preview-images {
  width: 100vw;
}

.image-gallery-slides {
  padding: 45px 150px;
  height: 100vh;
}
.modal-preview-images .ant-modal {
  padding-bottom: 0;
}

.image-gallery-slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 136px);
}

.imgPreview.edit-status video {
  max-width: 100%;
}

@keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.flex.center {
  align-items: center;
  justify-content: space-between;
}

.loaded:not(.has-error) {
  animation: loaded 1000ms ease-in-out;
}

.top-trending__tag-name {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-poll-from .ant-row.ant-form-item {
  margin-bottom: 0;
}

.create-poll-from .ant-col.ant-form-item-label {
  margin-bottom: 0;
}

.voter-card-horizontal-padding.title {
  height: 58px;
}

.voter-card-horizontal-padding {
  padding-top: 7px;
  padding-left: 16px;
  padding-right: 16px;
}
.notification-content.voter-card-horizontal-padding {
  height: initial;
  max-height: 400px;
}

.close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.modal-voters-views .ant-modal-content {
  border-radius: 10px;
}

.modal-voters-views .gray-divider {
  padding: 0;
  height: 8px;
  background-color: #fafafa;
  display: block;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.poll-option-content {
  position: absolute;
  margin-left: 16px;
}

.edit-heatmap-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e8e8e8;
}

.ant-message {
  z-index: 10011;
}

.fabric-canvas {
  height: 100%;
}

.popover-color-picker .ant-popover-inner-content {
  padding: 0 !important;
}

.popover-font-picker .ant-popover-inner-content {
  max-height: 200px;
  overflow: scroll;
}

.emoji-mart-emoji {
  vertical-align: middle;
  margin-top: -4px;
}

.custom-emoji {
  height: 22px;
}

.status-background-item.active > button {
  border: 2px solid #000;
}

.status-background-item > button {
  padding: 0;
  box-sizing: content-box;
}

.content-padding {
  margin-left: 9%;
  margin-right: 9%;
}

.error-page {
  display: grid;
  height: 100%;
  margin: 0;
  padding: 0;
  place-items: center;
  width: 100%;
}

.error-page .error {
  padding: 0.5rem;
  text-align: center;
}

.error-page .card {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 50px auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 20px 50px;
}

.error-page .message {
  margin-bottom: 1.5rem;
}

.error-page button {
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0.75rem;
  padding: 0.75rem 1rem;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.error-page h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.error-page p {
  font-size: 1.1rem;
  line-height: 2rem;
  margin: 0 0 1.5rem;
  padding: 0 1rem;
}

.logout-modal .ant-modal-body {
  border-radius: 5px;
  padding: 32px 24px;
}

.logout-modal .ant-modal-body .title {
  color: #141414;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.logout-modal .ant-modal-body .description {
  color: #595959;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 0;
}

.logout-modal .ant-modal-body .signin {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.logout-modal .ant-modal-body .button-signin {
  padding: 6px 16px;
  width: 150px;
  background-color: #060606;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.input-rename {
  padding-left: 8px !important;
}

.pick-range-calender .ant-picker-cell-today:hover {
  color: black;
  background: none;
}

.pick-range-calender .ant-picker-cell-range-end {
  background-color: none;
}

.pick-range-calender .ant-picker-cell-inner {
  border-radius: 0px;
  margin: auto;
}

.pick-range-calender .ant-picker-decade-panel .ant-picker-cell-inner {
  width: 45px;
  height: 40px;
  line-height: normal;
}

.pick-range-calender .ant-picker-year-panel .ant-picker-cell-range-hover::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-year-panel .ant-picker-cell-range-hover-end::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-year-panel .ant-picker-cell-range-hover-start::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-panel-has-range-hover .ant-picker-year-panel .ant-picker-cell-inner:hover {
  background-color: #f5f5f5;
}

.pick-range-calender .ant-picker-month-panel .ant-picker-cell-range-hover::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  border: 0px !important;
}

.pick-range-calender .ant-picker-panel-has-range-hover .ant-picker-month-panel .ant-picker-cell-inner:hover {
  background-color: #f5f5f5;
}

.ant-picker-decade-btn {
  pointer-events: none;
}

.pick-range-calender {
  .ant-picker-year-btn,
  .ant-picker-month-btn,
  .ant-picker-content {
    font-size: 12px;
  }
}
.favorite-projects-modal {
  padding: 0;
}
.favorite-projects-modal .ant-modal-body {
  padding: 16px 24px;
  border-radius: 6px;
  background-color: #fff;
}

.favorite-projects-modal .ant-modal-content {
  border-radius: 6px;
}

.project-checkbox-container {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.project-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.project-checkbox-checkmark {
  height: 16px;
  width: 16px;
  background-color: #fdfdfd;
  outline: solid 1px #e3e3e3;
  outline-offset: -1px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.project-checkbox-container:hover input ~ .project-checkbox-checkmark {
  outline: solid 1px #1a1a1a;
}

/* When the checkbox is checked, add a blue background */
.project-checkbox-container input:checked ~ .project-checkbox-checkmark {
  outline: solid 1px #1a1a1a;
  background-color: #1a1a1a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.project-checkbox-checkmark img {
  display: none;
}

/* Show the checkmark when checked */
.project-checkbox-container input:checked ~ .project-checkbox-checkmark img {
  display: block;
}


#tooltip2 {
  position: absolute;
  height: fit-content;
  max-width: 250px;
  padding: 12px;
  background-color: #FFFAA0;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

.tooltip-area:hover #tooltip2 {
  opacity: 1;
}

.history-list {
  padding-bottom: 12px;
}

.history-list:last-child {
  padding-bottom: 0px;
}

.history-list .ant-list-items {
  display: grid;
  gap: 14px;
}
.modal-wrap-mood {
  background-color: rgba(26, 26, 26, 0.72);

}

.show-mood-modal{
  min-width: 536px;
  padding-bottom: 0;
}

.show-mood-modal .ant-modal-content {
  border-radius: 6px;
}

.show-mood-modal .ant-modal-header {
  border-bottom: none;
  padding: 16px 24px;
  border-radius: 6px 6px 0px 0px;
}

.show-mood-modal .ant-modal-body {
  padding: 22px 24px 32px 24px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.show-mood-modal .ant-modal-footer {
  border-top: none;
  padding: 0px 24px 22px 24px;
  display: flex;
  justify-content: flex-end;
}

@keyframes shaking {
  0%,
  60%,
  100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(6deg);
  }
  50% {
    transform: rotate(-6deg);
  }
}

.shaking-animation {
  animation: shaking 0.9s ease-in-out;
  pointer-events: all;
  transform-origin: 50% 50%;
}

.popup-date-picker .ant-picker-cell-today:hover {
  color: black;
  background: none;
}

.popup-date-picker .ant-picker-cell-inner {
  border-radius: 0px;
  margin: auto;
}

.popup-date-picker .ant-picker-year-panel .ant-picker-cell-inner {
  width: 48px;
  height: 35px;
  line-height: normal;
}

.popup-date-picker .ant-picker-decade-panel .ant-picker-cell-inner {
  width: 48px;
  height: 40px;
  line-height: normal;
}

.modal-changlog .ant-timeline-item-tail {
  border-left: 1px dashed #d9d9d9;
  opacity: 85.1%;
  top: 8px;
  height: calc(100% - 12px);
  top: 13px;
}

.modal-changlog .ant-timeline-item-head {
  background-color: transparent;
}

.modal-changlog .ant-timeline-item-content {
  margin: 0px 0px 0px 30px;
  top: -2px;
}

.modal-changlog .ant-timeline-item-head-custom {
  padding: 0px;
}

.modal-changlog .ant-timeline {
  padding-top: 2px;
  padding-left: 2px;
}

.modal-changlog .ant-modal {
  min-width: 960px;
}

.modal-changlog .ant-modal-content {
  border-radius: 12px;
  background-color: #ffffff;
}

.modal-changlog .ant-modal-header {
  border-radius: 12px 12px 0px 0px;
  padding: 16px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
}

.modal-changlog .ant-modal-title {
  font-family: 'Poppins', sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.modal-changlog {
  [class~='ant-modal-close-x'] {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 20px;
    height: 20px;
  }
  [class~='ant-modal-close-icon'] {
    position: absolute;
    top: 4.6px;
    right: 4.6px;
    svg {
      height: 10.8px;
      width: 10.8px;
      color: #a8a8a8;
    }
  }
}

.modal-changlog .ant-modal-body {
 padding: 20px 32px 0px 32px;
 max-height: 490px;
 overflow-y: auto;
}

.modal-changlog .ant-timeline-item-last {
padding-bottom: 0px;
}

.modal-changlog .ant-modal-footer {
  background-color: #ffffff;
  border-top: none;
  height: 32px;
  border-radius: 12px;
  padding: 0px;
 }

 .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
   .anticon-exclamation-circle {
    display: none;
   }
   .ant-modal-confirm-content {
    margin: 0 !important;
   }
 }
 .ant-modal-confirm-btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  .ant-btn {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #F1F1F1;
    color: #1A1A1A;
    padding: 10px 24px;
    box-shadow: none;
  }
  .ant-btn-primary {
    color: #ffff;
    background-color: #1a1a1a;
  }
 }

 .comment-item {
  height: calc(100% - 47px);
  #list-comment-posts {
    height: 100%;
  }
}
.space-item > .ant-space-item:nth-child(2) {
  height: calc(100% - 110px);
}

.space-item-read > .ant-space-item:nth-child(2) {
  height: calc(100% - 80px);
}