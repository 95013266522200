.poll-editor .DraftEditor-root {
  min-height: 100px;
}

.option-item {
  height: 40px;
  padding: 8px;
  margin: 5px 0px;
  display: flex;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #EAEAEA;
  justify-content: space-between;
}

.option-item input {
  border: none;
}

.create-poll-modal .ant-modal {
  overflow: hidden;
  border-radius: 10px;
}
.create-poll-modal .ant-modal-title {
  font-size: 24px;
  font-weight: 600;
}
.create-poll-modal .ant-modal-body {
  padding-top: 5px;
}

.create-poll-modal .ant-checkbox-wrapper {
  margin: 8px 4px;
}

.create-poll-modal .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #060606;
  background-color: #060606;
}

.create-poll-modal .ant-col.ant-form-item-label {
  padding-bottom: 0;
}

.create-poll-modal label[for="poll_date"]:before {
  display: none;
}

.poll__adding-member {
  top: 0;
  left: 0;
  bottom: 0px;
  width: 520px;
  z-index: 100;
  border: none;
  /* height: 693px; */
  border-radius: 0;
  position: absolute;
  transition:left 0.3s linear;
}
.poll__adding-member  .ant-form-item-control-input{
  height: 100%;
}
.poll__adding-member .ant-form-item-control-input-content {
  height: 100%;
}
.poll__adding-member  .ant-form-item-control-input-content > div{
  border: none;
  padding-bottom: 24px;
}

.poll__adding-member .back-button {
  background-color: #EAEAEA;
}

.poll__adding-member .ant-input-affix-wrapper {
  width: 78%;
}


.poll__adding-member.hide {
  left: 520px;
  overflow: hidden;
}

.poll__adding-member .list-member {
  display: block;
  overflow: auto;
  height: calc(100% - 72px);
}

.create-poll-modal .separate {
  height: 8px;
  width: 520px;
  margin-left: -24px;
  background-color: #FAFAFA;
}

.selected-user-avatars {
  height: 60px;
  width: 520px;
  margin: 10px 0;
  padding: 0 24px;
  margin-right: -24px;
  margin-left: -24px;
  justify-content: space-between;
  border-top: solid 8px #FAFAFA;
  border-bottom: solid 8px #FAFAFA;
}

.create-poll-modal .participants-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-poll-modal .participants-list.total-1 .member-avatars{
  width: 32px;
}
.create-poll-modal .participants-list.total-2 .member-avatars{
  width: 48px;
}
.create-poll-modal .participants-list.total-3 .member-avatars{
  width: 54px;
}
.create-poll-modal .participants-list .member-avatars {
  width: 60px;
  height: 32px;
  margin: 0 5px;
  position: relative;
}
.create-poll-modal .participants-list .has-more {
  margin: 0 5px;
}
.create-poll-modal .participants-list .member-avatars > img {
  position: absolute;
  border-radius: 8px;
  border: solid 1px #FAFAFA;
  background-color: #FFFFFF;
}

.create-poll-modal  .participants-list .member-avatars > img:first{
  left: 0;
}
.create-poll-modal  .participants-list .member-avatars > img:nth-child(2) {
  left: 16px;
}
.create-poll-modal  .participants-list .member-avatars > img:nth-child(3) {
  left: 32px;
}

.main-container .participants-list {
  height: 36px;
}
.main-container .participants-list .member-avatars > img {
  width: 21px;
  height: 21px;
  position: absolute;
  border-radius: 6px;
  border: solid 1px #FAFAFA;
  background-color: #FFFFFF;
}
.main-container .participants-list .member-avatars > img:first {
  left: 0;
}
.main-container .participants-list .member-avatars > img:nth-child(2) {
  left: 11px;
}
.main-container .participants-list .member-avatars > img:nth-child(3) {
  left: 22px;
}

.main-container .participants-list .member-avatars {
  width: 48px;
  position: relative;
}

.main-container .participants-list.total-1 .member-avatars{
  width: 22px;
}
.main-container .participants-list.total-2 .member-avatars{
  width: 33px;
}
.main-container .participants-list.total-3 .member-avatars{
  width: 44px;
}
